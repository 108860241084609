import { compile } from 'path-to-regexp';
import { stringify } from 'query-string';
import { isEmpty, lowerCase } from 'lodash';

export default {
  index: '/',
  logout: '/logout',
  loginCallback: '/login-callback',
  switchLicense: '/switch-license',
  reports: '/reports',
  // inventory: {
  //   perpetual: {
  //     index: '/inventory/perpetual',
  //     details: '/inventory/perpetual/:categoryName/measure/:measure',
  //   },
  // },
  admin: {
    rooms: '/admin/rooms',
    employees: '/admin/employees',
    strains: '/admin/strains',
    ccrs: '/admin/ccrs',
  },
  orders: {
    printLabels: '/orders/print-labels',
  },
};

const _compiledRouteCache: { [key: string]: (pathParams: any) => string } = {};
function encode(str: any): string {
  return lowerCase(encodeURIComponent(str));
}

export function generateUrl(
  path: string,
  pathParams: any = {},
  queryParams?: any
): string {
  if (!_compiledRouteCache[path]) {
    _compiledRouteCache[path] = compile(path, { encode });
  }

  let url = _compiledRouteCache[path](pathParams);

  if (!isEmpty(queryParams)) {
    url += `?${stringify(queryParams)}`;
  }

  return url;
}
